<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="content">
            <div v-for="(item,index) in list" :key="index">
                <div class="top">
                    <div class="left">
                        <img :src="item.topUrl" alt="">
                        <span>{{item.toptitle}}</span>
                    </div>
                    <div class="right">
                        <span>{{item.toptime}}</span>
                    </div>
                </div>
                <div class="bottom">
                    <div class="left"><img :src="item.leftUrl" alt=""></div>
                    <div class="middle">
                        <p>{{item.title}}</p>
                        <p>{{item.content}}</p>
                    </div>
                    <div class="bottom_right">
                        <div :hidden="!item.bottomUrl">
                            <img  :src="item.bottomUrl" alt="">
                        </div>
                        <!-- <div class="copy" :class="{'leftTrans':item.bottomUrl}" v-if="item.copytext">
                            <span>{{item.copytext}}</span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
            list:[
                // {
                //     topUrl:require('../../assets/images/service.png'),
                //     toptitle:'QQ客服',
                //     toptime:'09:00～23:00',
                //     leftUrl:require('../../assets/images/qq.png'),
                //     title:'客服-小林',
                //     content:'QQ:',
                //     copytext:'复制QQ号'
                // },
                {
                    topUrl:require('../../assets/images/service.png'),
                    toptitle:'微信客服',
                    toptime:'09:00～23:00',
                    leftUrl:require(''+'../../../public/logo.jpg'+''),
                    title:'管理员',
                    content:'微信号：haojingchou6',
                    copytext:'复制微信号'
                },
                {
                    topUrl:require('../../assets/images/weixin.png'),
                    toptitle:'公众号',
                    toptime:'',
                    leftUrl:require('../../../public/logo.jpg'),
                    title:'好劲抽奖表',
                    content:'好劲抽官方公众号，每期都有预测心水免费提供',
                    copytext:'复制公众号'
                }
            ]
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        width: 100%;
        min-height: 100%;
        background-color: #eee;
        font-size: 16px;
        .content{
            width: 94%;
            height: 100vh;
            margin: 0 auto;
            .top{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 20px;
            font-size: 16px;
            height: 32px;
                .left{
                    img{
                        width: 20px;
                        height: 20px;
                        vertical-align: middle;
                        padding: 0 10px;
                    }
                }
            
            }
            .bottom{
                width: 97%;
                height: 120px;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 5px;
                position: relative;
                .left{
                    img{
                        width: 56px;
                        height: 56px;
                        /* border-radius: 50%; */
                        margin-right: 10px;
                    }
                }
                .middle{
                    
                }
                .bottom_right{
                    position: absolute;
                    right: 6px;
                    img{
                        width: 20px;
                        height: 20px;
                    }
                    .copy{
                        width: 100px;
                        height: 20px;
                        background-color: #eee;
                        font-size: 12px;
                        text-align: center;
                        border-radius: 6px;
                        position: absolute;
                        bottom: -56px;
                        right: 6px;
                        line-height: 20px;
                        &:active{
                            background-color: #ddd;
                        }
                    }
                }
            }
        }
    }
    .leftTrans{
        position: absolute;
        bottom: -46px !important;
    }
</style>